import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'

import Layout from '../components/layout'
import Seo from '../components/seo'
import MetaList from '../components/meta-list'
import ShareSidebar from '../components/share-sidebar'
import ImageGallery from '../components/image-gallery'
import Prose from '../components/prose'
import AffiliateDisclaimer from '../components/affiliate-disclaimer'
import SubscribeCta from '../components/subscribe-cta'
import PublishDate from '../components/publish-date'

export const query = graphql`
  query IdeaBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      fields: {
        content_type: { eq: "ideas" }
        slug: { eq: $slug }
      }
    ) {
      html
      frontmatter {
        title
        publishedOn: published_on(formatString: "MMMM D, YYYY")
        publishedOnIso: published_on(formatString: "YYYY-MM-DD")
        modifiedOn: modified_on(formatString: "MMMM D, YYYY")
        modifiedOnIso: modified_on(formatString: "YYYY-MM-DD")
        meta {
          description
        }
        imageGallery: image_gallery {
          thumb: file {
            childImageSharp {
              base: gatsbyImageData(width: 70, height: 70, transformOptions: {
                cropFocus: CENTER
              })
              large: gatsbyImageData(width: 93, height: 93, transformOptions: {
                cropFocus: CENTER
              })
            }
          }
          full: file {
            childImageSharp {
              gatsbyImageData(width: 825, transformOptions: {
                cropFocus: CENTER
              })
            }
          }
          file {
            name
            ext
          }
          alt
        }
        summary
        hasAffiliateLinks: has_affiliate_links
      }
    }
  }
`

const IdeaTemplate = ({ data, pageContext }) => {
  const idea = data.markdownRemark
  const meta = idea.frontmatter
  const slug = pageContext.slug.replace(/\//g, '')
  const canonicalUrl = data.site.siteMetadata.siteUrl + pageContext.slug

  const metaDesc = (meta.meta && meta.meta.description
    ? meta.meta.description
    : meta.summary
  )

  const socialUrlBase = [
    data.site.siteMetadata.siteUrl,
    '/images/social/',
    slug,
    '/',
    slug,
  ].join('')

  const ogImageUrl = `${socialUrlBase}-open-graph.jpg`
  const twitterImageUrl = `${socialUrlBase}-twitter.jpg`
  const pinterestImageUrl = `${socialUrlBase}-pinterest.jpg`

  return (
    <Layout>
      <Seo
        title={`${meta.title}`}
        description={metaDesc}
        canonicalUrl={canonicalUrl}
        meta={[
          {
            property: 'og:type',
            content: 'article',
          },
          {
            property: 'article:section',
            content: 'S’mores Ideas',
          },
          {
            property: 'article:published_time',
            content: meta.publishedOnIso,
          },
          {
            property: 'article:modified_time',
            content: meta.updatedOnIso,
          },
          {
            property: 'article:author',
            content: data.site.siteMetadata.title,
          },
          {
            property: 'og:image',
            content: ogImageUrl,
          },
          {
            property: 'og:image:alt',
            content: meta.title,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            name: 'twitter:image',
            content: twitterImageUrl,
          },
          {
            name: 'twitter:image:alt',
            content: meta.title,
          },
        ]}
        schema={buildSchema(data, pageContext)}
      />

      <article className="section pb-5">
        <Container>
          <Row className="justify-content-around">
            <Col md={10} lg={9} className="mb-3">
              <Breadcrumb>
                <Breadcrumb.Item
                  href="/"
                  linkProps={{ className: "breadcrumb-link" }}
                >
                  Home
                </Breadcrumb.Item>

                <Breadcrumb.Item
                  href="/ideas/"
                  linkProps={{ className: "breadcrumb-link" }}
                >
                  Ideas
                </Breadcrumb.Item>
              </Breadcrumb>

              <h1>{meta.title}</h1>

              <MetaList>
                <MetaList.Item>
                  <PublishDate
                    publishedOn={meta.publishedOn}
                    publishedOnIso={meta.publishedOnIso}
                    modifiedOn={meta.modifiedOn}
                    modifiedOnIso={meta.modifiedOnIso}
                  />
                </MetaList.Item>
              </MetaList>

              <ShareSidebar
                title={`Idea: ${meta.title}`}
                pinterestMedia={pinterestImageUrl}
              />

              <ImageGallery
                images={meta.imageGallery}
                className="prose-margin"
              />
            </Col>
          </Row>

          <Row className="justify-content-around">
            <Col md={6} lg={7} className="mb-5 mb-md-0">
              <p className="lead mb-5">
                {meta.summary}
              </p>

              <div className="mb-5">
                <Prose hasRawHtml={true} mb={5}>
                  {idea.html}
                </Prose>
              </div>

              {meta.hasAffiliateLinks ?
                <AffiliateDisclaimer />
              : null}
            </Col>

            {/*<Col md={6} lg={5} className="pl-xl-5">
              {(() => {
                if (relatedContent && relatedContent.length) {
                  return (
                    <>
                      <h2 className="h4 mt-5">
                        Related
                      </h2>
                      <RelatedContent relatedContent={relatedContent} />
                    </>
                  )
                }
              })()}
            </Col>*/}
          </Row>
        </Container>
      </article>

      <SubscribeCta>
        <h2 className="h3 mb-3">
          Get s’more ideas like this one in your inbox
        </h2>
      </SubscribeCta>
    </Layout>
  )
}

function buildSchema(data, pageContext) {
  const site = data.site.siteMetadata
  const idea = data.markdownRemark
  const meta = idea.frontmatter
  const slug = pageContext.slug.replace(/\//g, '')

  const metaDesc = meta.meta && meta.meta.description
    ? meta.meta.description
    : meta.summary

  const socialUrlBase = [
    data.site.siteMetadata.siteUrl,
    '/images/social/',
    slug,
    '/',
    slug,
  ].join('')

  const searchImages = [
    `${socialUrlBase}-1x1.jpg`,
    `${socialUrlBase}-4x3.jpg`,
    `${socialUrlBase}-16x9.jpg`,
  ]

  const modifiedOnIso = meta.modifiedOnIso
    ? meta.modifiedOnIso
    : idea.publishedOnIso

  const schema = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    image: searchImages,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://www.smorescout.com/${slug}/`,
    },
    headline: meta.title,
    datePublished: meta.publishedOnIso,
    dateModified: modifiedOnIso,
    author: {
      "@type": "Person",
      name: site.title,
    },
    description: metaDesc.trim(),
  }

  return schema
}

export default IdeaTemplate
