import React from 'react'

import InfoBox from './info-box'

const AffiliateDisclaimer = () => (
  <InfoBox>
    <InfoBox.Body>
      <p className="small">
        DISCLOSURE: S’more Scout receives a commission from some third party
        websites if you decide to make a purchase through one of our
        &ldquo;Buy&rdquo; links. This happens at no additional cost to you.
      </p>
    </InfoBox.Body>
  </InfoBox>
)

export default AffiliateDisclaimer
